import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const GoodBadMeta = () => (
  <Layout>
    <SEO
      title="The Good and Bad of Meta"
      description="Hearing whispers of a Facebook name change. As I was listening, my mind wandered into projections of the future and what the world would be like, should this idea succeed.."
    />
    <h1>The Good and Bad of <del>Facebook</del> Meta</h1>

    <h2>Change is Coming</h2>
    <p>Over the past few days, I had heard whispers of a Facebook name change. I dismissed this as some false information because why and how would Mr Zuckerberg do that. This isn’t some dodgy company with a mysterious CEO that is trying to hide some secrets from future customers. This is one of the biggest and well known companies in the world.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/1LT6WUz.jpg"
        alt="the good and bad of meta"
        description="woman sitting under some curtain netting surrounded by fairy lights with vr headset on and controller in hand"
      />
    </div>
    <p>
      Photo by{" "} 
      <a
        href="https://unsplash.com/@barbarazandovalt"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      Barbara Zandoval
      </a>
      {" "} on Unsplash
    </p>

    <p>The whispers got louder and eventually a video was released on the Meta YouTube channel featuring Mark himself. At over an hour long I decided to play it in the background while I did some work, stopping my work periodically to rewind and take in some of the seemingly more important notes.</p>
    <p>As I was listening, my mind wandered into projections of the future and what the world would be like, should this idea succeed. Mr Zuckerberg repeatedly urges us all to work together to see this vision through but what if we do enter the Metaverse, what awaits us? I’ll go over some of the good and bad things based on my first impression. I must admit, I didn’t finish the video, having about 20 minutes left.</p>

    <h2>Initial Thoughts</h2>

    <h2>Behaviour</h2>
    <p>Crazy behaviour bleeding into real life. New age internet users don’t care about privacy and while having your personal information out there is a deterrent for bad behaviour in older generations, my theory is that this isn’t the case in the young. The internet is all they have ever known and expressing yourself isn’t as feared. With VR and AR, the lines between reality and the aforementioned will start to get blurred.</p>

    <h2>Augmented Reality Productivity</h2>
    <p>The virtual computer looks amazing. This is one of the features in the demonstration that I felt excited to try out. In case you missed it, you put on the glasses and you get an AR computer setup on your desk. You can have multiple screens and I imagine you’ll be able to connect a real keyboard & mouse for some of that physical feedback. Mr Zuckerberg spoke about having the processing power in the glasses but I think remote processing power could be an option. Hopefully the average internet connection is able to make for a smooth user experience.</p>

    <h2>Time Spent on Social Media</h2>
    <p>The total time spent on social media will be horrific. One of the things that takes us away from social media and keeps us from spending all day on it is fatigue. In other words, we get bored eventually. I get the feeling that Meta is attempting to do away with said fatigue so we can spend all of our unproductive time on it.</p>
    <p>More time spent equals more money which in this case is a good thing for Meta. The advertising opportunities will be a marketer’s dream. Imagine traditional advertising like billboard and radio, In a virtual world these can be reinvented. The reason marketers like digital ads is that they can be tracked. Wouldn’t it be great if billboard and radio ads can be tracked and analysed. Well, in <del>the matrix</del> Meta, this will all be possible.</p>

    <h2>Revenue</h2>
    <p>Back to my original point on more money. I’m sure you’ve heard that if you aren’t paying for the product then you are the product. Free social media platforms reach profitability through ads. Imagine how many ads you can show to someone who is going to spend most of their evening on this. I spend a disproportionate amount of time on YouTube and I often reach breaking point once I’ve seen one too many ads. Again, what if the ads don’t interfere with the task at hand and don’t cause fatigue?</p>

    <h2>Data Data Data!</h2>
    <p>The data collection will reach a whole new level. There is a demonstration of a 3D scanned person along with their body. Yes, this will provide a good user experience because I haven’t seen a better CGI copy of a person outside of a film. But, you are giving away even more data.</p>
    <p>More on data. Mark is encouraging us to spend a lot of time here and interact with our friends, all around the world if you can. There’s even the possibility of using Meta for work. The problem with this is that Meta will potentially learn so much more about you. It will have access to your conversations between colleagues and friends. If you thought Google was bad for spying on your emails then Meta will be on a whole new level. Your phone won’t even need to listen to you anymore, it will be right in the terms and conditions of Meta when you sign up.</p>

    <h2>Ads</h2>
    <p>We should all get better advertising. I’m spending a lot of time speaking about advertising but trust me, it’s important. Without it, we would be paying a monthly subscription to pay for social media and I don’t think that’s something the world is ready for just yet. More information leads to better advertising.</p>
    <p>Back to older methods of advertising like radio and television, they took a shot gun approved. Blast from a distance and hope you catch something. It’s how you end up with complete mismatches in the audience like kids hearing adverts for cars or the elderly with no grandchildren getting messages for children cereal.</p>
    <p>Step into the modern age and we can target based on interests meaning more specific ads. In the gun analogy, we’ve moved onto assault rifles. More accurate and easier to focus on specific targets.</p>
    <p>The new age of ads in Meta will be compared to a sniper rifle in good hands. They will be much more specific to you and that’s because, as I’ve alluded to, there will be much more accurate information about you, making you stand out as an individual. With more data comes more power to advertisers. The tricky part will be finding a balance between a good relevant ad and one that is way too intrusive and makes you wonder if you’re giving too much away.</p>

  

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default GoodBadMeta
